<template>
  <div
    ref="modal"
    tabindex="-1"
    role="dialog"
    :aria-label="label"
    class="modal__container"
    @keyup.esc="close"
  >
    <slot name="content" />
    <button class="screenreader-only" @click="close">End of section. Return to main content.</button>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: 'modal'
    },
    isOpen: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.saveFocus()
        this.lockBodyScroll()
        document.addEventListener('focusin', this.trapFocusInsideOfModal)
        this.$refs.modal.focus()
      } else {
        document.removeEventListener('focusin', this.trapFocusInsideOfModal)
        this.retrieveBodyScroll()
        this.retrieveFocus()
      }
    }
  },
  methods: {
    // prevents keyboard focus from falling outside of modal
    trapFocusInsideOfModal(event) {
      const modal = this.$refs.modal
      if (!modal.contains(event.target)) {
        event.stopPropagation()
        modal.focus()
      }
    },
    // saves the last active element to put the focus back after modal closes
    saveFocus() {
      this.pageLastFocus = document.activeElement
    },
    retrieveFocus() {
      if (this.pageLastFocus) {
        this.pageLastFocus.focus()
      }
    },
    lockBodyScroll() {
      this.pageTop = window.pageYOffset
      document.body.style.overflowY = 'hidden'
      document.body.style.height = '100vh'
    },
    retrieveBodyScroll() {
      document.body.style.overflowY = 'visible'
      document.body.style.height = 'auto'
      window.scrollTo(0, this.pageTop)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__container {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>