<template>
  <div id="server-messages" v-if="errorMessage" class="content pt-xs mt-md mb-md">
    <div class="header has-text-weight-bold">{{ header }}</div>
    <span v-html="errorMessage"/>
  </div>
</template>

<script>
export default {
  name: "ServerMessages",
  props: {
    header: {
      type: String,
      default: 'Check the following:'
    },
  },
  data() {
    const sbErrorMessages = document.querySelector("#springboard-server-messages .error")
    return {
      errorMessage: sbErrorMessages ? sbErrorMessages.outerHTML : ''
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

#server-messages {
  border-top: solid 2px $red-AA-compliant;
  .header {
    color: $red-AA-compliant;
  }
  :deep(.error) {
    .sb-msg-close {
      display: none;
    }
    ul {
      margin-left: 0;
      margin-top: 0;
    }
    background-image: none;
    padding-left: 2rem;
    padding-top: 1rem;
    background-color: transparent;
    color: black;
  }
}
</style>