<template>
  <div>
    <SpringboardDropdown
      :sourceElement="salutationSourceElement"
      @value-changed="updateSalutationValue" />
    <SpringboardDropdown
      :sourceElement="altSalutationSourceElement"
      :requiredOverride="altSalutationRequired"
      @value-changed="updateSalutationAltValue"
      v-if="altSalutationRequired"
      class="mt-sm" />
    <p v-if="altSalutationRequired" class="is-size-7 mt-xxs">Most elected officials' constituent email systems accept a very limited set of salutations. Please select the one you would most prefer.</p>
  </div>
</template>

<script>
// This component is designed to work in concert with Springboard's built-in sba_message_action-salutations.js.
// The sba_message_action-salutations.js controls when the alt salutation field is visible and also creates and
// sets the value of a hidden element that gets submitted with the form.

import SpringboardDropdown from "./SpringboardDropdown.vue"

const changeEvent = new Event('change')

export default {
  name: "SalutationFields",
  components: {
    SpringboardDropdown,
  },
  props: {
    salutationSourceElement: {
      type: HTMLSelectElement,
      required: true
    },
    altSalutationSourceElement: {
      type: HTMLSelectElement,
      required: true
    },
  },
  data() {
    return {
      salutationValue: this.salutationSourceElement.value,
      altSalutationOptionValues: [...this.altSalutationSourceElement.options].map(o => o.value)
    }
  },
  methods: {
    updateSalutationValue(newVal) {
      this.salutationValue = newVal
      this.salutationSourceElement.dispatchEvent(changeEvent)
    },
    updateSalutationAltValue() {
      this.altSalutationSourceElement.dispatchEvent(changeEvent)
    }
  },
  computed: {
    altSalutationRequired: function () {
      // Only require the alt salutation field if the non-alt salutation is set to something
      // not available as an option in the alt salutation dropdown. This follows the logic
      // in Springboard's `sba_message_action-salutations.js` for displaying/hiding the alt
      // salutation field.
      return !this.altSalutationOptionValues.includes(this.salutationValue)
    }
  },
}
</script>
