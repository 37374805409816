<template>
  <div class="field is-flex is-flex-wrap-wrap textarea-wrapper">
    <label v-show="showLabel" :for="vueElementId" :style="labelCssVars" class="has-text-weight-bold mb-xxs">
      {{ elementLabel }}
      <span v-if="required" title="This field is required.">*</span>
    </label>
    <div class="control">
      <textarea
        :type="sourceElement.type"
        :name="sourceElement.name"
        :required="required"
        :class="[sourceElement.className, labelStyling]"
        :id="vueElementId"
        :cols="sourceElement.cols"
        :rows="sourceElement.rows"
        v-model="inputTextArea"
        ref="textarea"
        class="textarea" />
      <div v-if="hasError" class="error-icon" />
    </div>
  </div>
</template>

<script>
import { observeElement } from '../../index'

export default {
  name: "SpringboardTextArea",
  props: {
    sourceElement: {
      type: HTMLTextAreaElement,
      required: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelInline: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      required: this.sourceElement.classList.contains('required'),
      defaultLabel: this.sourceElement.parentElement.querySelector('label').textContent.replace(" *", "").trim(),
      hasError: this.sourceElement.classList.contains('error'),
      forMessageAction: true,
      inputTextArea: this.sourceElement.value
    }
  },
  computed: {
    labelCssVars() {
      return {
        '--label-position': this.labelInline ? 'absolute' : 'relative',
        '--label-padding-top': this.labelInline ? '7px' : '0',
        '--label-left': this.labelInline ? '10px' : '0',
        '--label-font-size': this.labelInline ? '13px' : '1em'
      }
    },
    labelStyling() {
      const defaultFormLabel = (this.labelInline && this.showLabel) ? 'inline-label-padding' : ''
      return this.forMessageAction ? defaultFormLabel + ' message-action-text' : defaultFormLabel
    },
    elementLabel() {
      if (this.label === '')
        return this.defaultLabel
      else
        return this.label
    },
    vueElementId() {
      return this.sourceElement.id + '-vue'
    }
  },
  created() {
    // watches the SB element's value for changes
    // and applies them to the vue component's value
    observeElement(this.sourceElement, 'value', this, function (oldValue, newValue) {
      if (newValue != this.inputTextArea) {
        this.inputTextArea = newValue
      }
    })
  },
  mounted() {
    this.$refs.textarea.addEventListener("input", (event) => {
      if (this.sourceElement.value != this.inputTextArea) {
        this.sourceElement.value = this.inputTextArea
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";
@import '../../styles/error';

.textarea-wrapper {
  label {
    position: var(--label-position);
    padding-top: var(--label-padding-top);
    left: var(--label-left);
    z-index: 2;
    font-size: var(--label-font-size);
    font-family: $aclu-sans-standard;
    line-height: 1.4;
    letter-spacing: 0rem;
    word-spacing: 0.0625rem;
  }

  .textarea {
    border: solid 2px;
  }

  .control {
    min-width: 100%;
  }

  .inline-label-padding {
    padding: 25px 9px;
  }

  .message-action-text {
    padding: 5px 9px;
  }
}</style>
