<template>
  <div id="footer" class="affiliate-footer pt-md pb-md">
    <div class="footer-social-share">
      <FooterSocialLink
        v-for="(social) in socials"
        :affiliateName="affiliateName"
        :socialName="social.label"
        :url="social.link"
      />
    </div>
    <div class="affiliate-address mt-sm mb-sm is-size-6">
      <p>{{ affiliateName }}</p>
      <slot name="address"></slot>
      <p><a v-if="websiteUrl" :href="websiteUrl">Website</a></p>
    </div>
    <div role="navigation" class="block block-menu mb-xs">
      <ul class="menu">
        <li class="menu__item is-leaf first leaf is-size-7 is-inline-block m-0">
          <a
            v-if="userAgreementUrl"
            :href="userAgreementUrl"
            class="menu__link active"
            >User agreement</a
          >
        </li>
        <li class="menu__item is-leaf leaf last is-size-7 is-inline-block m-0">
          <a
            v-if="privacyStatementUrl"
            :href="privacyStatementUrl"
            class="menu__link active"
            >Privacy statement</a
          >
        </li>
        <li class="menu__item is-leaf leaf last is-size-7 is-inline-block m-0">
          <a
            v-if="accessibilityUrl"
            :href="accessibilityUrl"
            class="menu__link active"
            >Accessibility</a
          >
        </li>
      </ul>
    </div>
    <p class="footer-copyright mb-0 is-size-7">
      &copy; {{ copyrightYear }} ACLU
    </p>
  </div>
</template>

<script>
import FooterSocialLink from "./FooterSocialLink.vue";
export default {
  name: "AffiliateFooter",
  components: {
    FooterSocialLink,
  },
  props: {
    affiliateName: {
      type: String,
      default: "",
    },
    websiteUrl: {
      type: String,
      default: "",
    },
    socialUrls: {
      type: String,
      validator: function (obj) {
        obj = JSON.parse(obj.trim());
        const socials = ['Facebook', 'X', 'Instagram', 'YouTube', 'TikTok', 'Threads'];
        for (var i = 0; i < obj.length; i++) {
          const socialUrl = obj[i];
          if (!socials.includes(socialUrl.label)) {
            return false;
          }
        }
        return true;
      },
    },
    userAgreementUrl: {
      type: String,
      default: "",
    },
    privacyStatementUrl: {
      type: String,
      default: "",
    },
    accessibilityUrl: {
      type: String,
      default: "",
    },
    copyrightYear: {
      type: String,
      default: "",
    },
  },
  computed: {
    socials() {
      return JSON.parse(this.socialUrls.trim()) || {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.affiliate-footer {
  .affiliate-address {
    p,
    .street-block,
    .thoroughfare,
    .addressfield-container-inline.locality-block.country-US,
    .country {
      color: $grey-80;
      line-height: 1.5;
    }
    a {
      color: $grey-80;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .footer-copyright {
    color: $grey-80;
  }

  ul.menu a {
    line-height: 1;
    color: $offblack;
    &:hover {
      opacity: 0.8;
    }
  }
  .menu__item.is-leaf {
    list-style: none outside none;
  }
}
</style>
