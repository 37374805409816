<template>
  <div v-if="springboardWebform">
    <div @click="infoFormClick">
      <section>
        <button class="button is-hidden-desktop is-fullwidth modal__close has-background-orange has-text-left wrap-button-text hp__messageaction_close_form" aria-label="close modal" @click="$emit('close-drawer')">
          <div class="container is-flex">
            <div class="is-flex-grow-2">
              <h3 class="mt-sm mb-sm">{{ submitButtonText }}</h3>
            </div>
            <div class="is-align-self-center">
              <Caret :width="10" :rotate="90" />
            </div>
          </div>
        </button>
      </section>

      <section class="container">
        <button class="button is-hidden-desktop preview-message is-fullwidth mt-sm mb-sm hp__messageaction_preview_message" @click="$emit('show-message-preview')">Preview message</button>
        <p class="has-text-weight-bold is-hidden-touch mb-sm">Fill out your information</p>
        <div class="is-flex is-mobile share-disclaimer mb-sm">
          <p class="is-flex-grow-3">Fields below are required by many public officials' systems. Data you enter here may be shared with your message recipient, per our <a href="https://www.aclu.org/about/privacy/statement" target="_blank">Privacy Statement</a>.</p>
          <div>
            <div class="tooltip-wrapper is-relative content">
              <div v-show="showTip" role="tooltip" id="tooltip-box" class="is-size-6 has-background-black has-text-white p-xs">
                <p>Many public officials use "web mail forms" that this system will send your message to, so you don't have to visit their web sites to communicate with them. The systems that some of these officials use requires that the honorific field be used (Mr., Mrs., Miss, etc.).</p>
                <p>Unfortunately, we do not have control of which honorific titles are presented as options. We must adhere to what the officials are using in order for your message to be delivered.</p>
              </div>
              <button class="button tooltip" @click.stop="toggleTip" aria-describedby="tooltip-box"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-describedby="public-officials-info">
                  <title id="public-officials-info">More information about public officials' systems</title>
                  <circle cx="10" cy="10.873" r="10" fill="#231F20" />
                  <rect x="8.79993" y="10.0731" width="2.4" height="6.6" fill="white" />
                  <rect x="8.80023" y="5.27295" width="2.4" height="2.4" fill="white" />
                </svg></button>
            </div>
          </div>
        </div>
        <section>
          <ServerMessages :header="'Check the following:'"/>
        </section>
        <div class="columns is-mobile is-multiline is-1 is-variable mt-sm">
          <SalutationFields class="column is-full pt-none" :salutationSourceElement="getSourceElementById('edit-submitted-sbp-salutation')" :altSalutationSourceElement="getSourceElementById('edit-submitted-sba-salutation-alternate')" />
          <div class="column is-half pt-none">
            <SpringboardText ref="firstNameElementWrapper" @fieldValueChange="updateFirstName" :sourceElement="getSourceElementById('edit-submitted-sbp-first-name')" />
          </div>
          <div class="column is-half pt-none">
            <SpringboardText ref="lastNameElementWrapper" @fieldValueChange="updateLastName" :sourceElement="getSourceElementById('edit-submitted-sbp-last-name')" />
          </div>
          <div class="column is-full pt-none">
            <SpringboardText :sourceElement="getSourceElementById('edit-submitted-sbp-address')" />
          </div>
          <div class="column is-half pt-none">
            <SpringboardText :sourceElement="getSourceElementById('edit-submitted-sbp-address-line-2')" />
          </div>
          <div class="column is-half pt-none">
            <SpringboardText @fieldValueChange="prepopulateCityState" :sourceElement="getSourceElementById('edit-submitted-sbp-zip')" />
          </div>
          <div class="column is-half pt-none">
            <SpringboardText :sourceElement="getSourceElementById('edit-submitted-sbp-city')" />
          </div>
          <div class="column is-half pt-none">
            <SpringboardDropdown :sourceElement="getSourceElementById('edit-submitted-sbp-state')" />
          </div>
          <div class="column is-full pt-none">
            <SpringboardText :sourceElement="getSourceElementById('edit-submitted-sbp-phone')" />
          </div>
          <div class="column is-full pt-none">
            <SpringboardText :sourceElement="getSourceElementById('edit-submitted-mail')" />
          </div>
          <div class="column is-full pt-none" v-if="isAffiliate">
            <SpringboardCheckbox :sourceElement="getSourceElementById('edit-submitted-sbp-sba-action-optin-1')" />
          </div>
          <div class="column is-full pt-none">
            <SpringboardCheckbox :sourceElement="getSourceElementById('edit-submitted-email-opt-in-1')" />
          </div>
          <div class="column is-full pt-none pb-none">
            <SpringboardCheckbox :sourceElement="getSourceElementById('edit-submitted-sms-opt-in-1')" />
          </div>
        </div>
        <div v-if="!isSubmitting" class="mb-md mt-md has-text-centered">
          <button type="button" class="is-hidden-desktop button is-black submit-button submit-button-touch hp__messageaction_send" @click="submitForm">Send</button>
          <button type="button" class="is-hidden-touch button is-fullwidth has-text-black has-background-orange submit-button hp__messageaction_send" @click="submitForm">Send message</button>
        </div>
        <div v-else class="mb-md mt-md has-text-centered" >
          <div class="is-hidden-desktop processing-button sumbission-processing-touch mx-auto has-background-black has-text-white">Processing</div>
          <div class="is-hidden-touch processing-button is-fullwidth has-background-orange has-text-black">Processing</div>
        </div>
      </section>
      <ModalPopup v-if="showModal" @close="showModal = false">
        <template #body>
          <p class="has-text-weight-bold">Your edits have been saved!</p>
        </template>
      </ModalPopup>
    </div>
  </div>
</template>

<script>
import SpringboardText from "./form-fields/SpringboardText.vue"
import SpringboardCheckbox from "./form-fields/SpringboardCheckbox.vue"
import SpringboardDropdown from "./form-fields/SpringboardDropdown.vue"
import SalutationFields from "./form-fields/SalutationFields.vue"
import Caret from '@aclu-national/aclu-vue-library/src/components/common/Caret.vue'
import ServerMessages from "./ServerMessages.vue"
import ModalPopup from './ModalPopup.vue'
import { configuredSubmitButtonText, messageActionBus } from '../message-action'
import { trackHiddenFormSubmitInHeap } from '../index'

export default {
  name: 'ConstituentInfoForm',
  components: {
    SpringboardText,
    SpringboardCheckbox,
    SpringboardDropdown,
    SalutationFields,
    Caret,
    ServerMessages,
    ModalPopup,
  },
  props: {
    showModal: false,
    isAffiliate: false
  },
  data() {
    return {
      submitButtonText: configuredSubmitButtonText,
      springboardWebform: document.querySelector('form.webform-client-form'),
      showTip: false,
      isSubmitting: false,
    }
  },
  methods: {
    getSourceElementById(id) {
      return document.getElementById(id)
    },
    toggleTip() {
      this.showTip = !this.showTip
    },
    infoFormClick() {
      this.showTip = false
    },
    updateFirstName(newValue) {
      messageActionBus.emit('update-first-name', newValue.trim())
    },
    updateLastName(newValue) {
      messageActionBus.emit('update-last-name', newValue.trim())
    },
    prepopulateCityState(zipCode) {
      const cityField = document.getElementById('edit-submitted-sbp-city')
      const stateField = document.getElementById('edit-submitted-sbp-state')
      const cityFieldIsEmpty = !cityField.value.trim()
      const stateFieldIsEmpty = !stateField.value.trim()

      // We check if the city or state fields are already populated so that we don't
      // overwrite anything the user manually input.
      if ((/^\d{5}$/.test(zipCode)) && (cityFieldIsEmpty || stateFieldIsEmpty)) {
        fetch("/zip_lookup/autopop-city-state-by-zip", {
          "headers": {
            "content-type": "application/x-www-form-urlencoded",
          },
          "body": `zip=${zipCode}`,
          "method": "POST",
        })
          .then(response => response.json())
          .then((responseJSON) => {
            if (responseJSON.city && cityFieldIsEmpty) {
              cityField.value = responseJSON.city
            }
            if (responseJSON.state_id && stateFieldIsEmpty) {
              stateField.value = responseJSON.state_id
            }
          })
      }
    },
    allFieldsValid() {
      const messageFields = this.$el.querySelectorAll(`[id^="edit-submitted-"]`)
      return Array.from(messageFields).every((field) => {
        const isCurrentFieldValid = field.reportValidity()
        if (!isCurrentFieldValid && !this.isInViewport(field)) {
          // safari on iOS wasn't always showing the field that had
          // an error, so we check if the field is visible and if not
          // we scroll to it.
          field.scrollIntoView()
        }
        if (!isCurrentFieldValid && (field !== document.activeElement)) {
          // firefox on android doesn't play well with native browser validation
          // (see: https://bugzilla.mozilla.org/show_bug.cgi?id=1510450), so we
          // do some of the work for them here.
          field.focus()
        }
        return isCurrentFieldValid
      })
    },
    submitForm() {
      if (!this.isSubmitting) {
        this.isSubmitting = true

        // we intentionally trigger the heap submission event before validation
        // to stay consistent with the `Submitted any send message action form`
        // event that is triggered on non-vue message action forms. the
        // `Submitted any send message action form` event triggers before any
        // validation as well.
        trackHiddenFormSubmitInHeap()
        if (this.allFieldsValid() && this.springboardWebform) {
          // In the Springboard backend there are 2 phone-related fields; one for SMS and the other
          // for autodial. But in the UI, we only want to show one checkbox. So we show the SMS
          // checkbox and hide the autodial checkbox. We keep the 2 fields in sync here so that the
          // one field we display represents both the supporter's sms preference and their autodial
          // preference.
          const autodialHiddenField = document.querySelector('input[name="submitted[mobile_autodial_opt_in]"]')
          const smsField = document.getElementById('edit-submitted-sms-opt-in-1')
          autodialHiddenField.value = smsField.checked ? '1' : ''

          // We want to implicitly opt the supporter into the social proof listing
          const socialProofField = document.getElementById('edit-submitted-sbp-sba-action-optin-1')
          socialProofField.checked = this.isAffiliate ? socialProofField.checked : true

          this.springboardWebform.submit()
        }
        else {
          this.isSubmitting = false
        }
      }
    },
    isInViewport: function (element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

// reset z-index all inputs so tooltip can appear on top
div.column {
  z-index: 0;
}

.wrap-button-text {
  height: auto;
  white-space: break-spaces;
}

button.modal__close {
  min-height: $inputHeightLarge;
}

button.preview-message {
  border: 2px solid black;
  border-radius: 5px;
}

button.submit-button,
.submission-processing {
  min-width: 225px;
}

.submit-button-touch,
.sumbission-processing-touch {
  max-width: 225px;
}

.share-disclaimer a {
  color: $grey-80;
}

button.tooltip {
  margin-top: -5px;
}

#tooltip-box {
  width: 240px;
  left: -240px;
  border: 1px solid black;
  position: absolute;
  z-index: 2;
}

// triangle
#tooltip-box:after {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  right: -7px;
  width: 20px;
  height: 20px;
  background: black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  transform: rotate(45deg);
  z-index: 2;
}</style>
