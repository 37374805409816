<template>
  <div class="is-flex">
    <input
      class="p-none mr-sm"
      ref="checkbox"
      :type="sourceElement.type"
      :name="sourceElement.name"
      :id="vueElementId"
      v-model="sourceElement.checked"
      value="sourceElement.value" />
    <label :for="vueElementId" class="is-size-7 has-text-weight-normal" v-html="label" />
  </div>
</template>

<script>
import { observeElement } from '../../index'

export default {
  props: {
    sourceElement: {
      type: HTMLInputElement,
    }
  },
  data() {
    return {
      label: this.sourceElement.parentElement.querySelector('label').innerHTML.trim(),
    }
  },
  computed: {
    vueElementId() {
      return this.sourceElement.id + '-vue'
    }
  },
  mounted() {
    this.$refs.checkbox.addEventListener("input", (event) => {
      if (this.sourceElement.checked != this.$refs.checkbox.checked) {
        this.sourceElement.checked = this.$refs.checkbox.checked
      }
    })
  },
  created() {
    // watches the SB element's checked state for changes
    // and applies them to the vue component's checked state
    observeElement(this.sourceElement, 'checked', this, function (oldChecked, newChecked) {
      if (newChecked != this.$refs.checkbox.checked) {
        this.$refs.checkbox.checked = newChecked
      }
    })
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/main.scss';

$checkboxSize: 20px;

label :deep(a) {
  color: $grey-80;
}

input,
label {
  cursor: pointer;
}

input[type='checkbox'] {
  min-width: $checkboxSize;
  width: $checkboxSize;
  height: $checkboxSize;

  &:checked {
    border: none;

    &:before {
      content: "";
      display: inline-block;
      width: $checkboxSize;
      height: $checkboxSize;
      background: icon-url('checkbox_black.svg');
    }
  }
}
</style>
