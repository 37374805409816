<template>
  <transition name="slide" :css="useTransition">
    <FullScreenModal
      v-if="isDrawerOpen"
      :is-open="isDrawerOpen"
      @close="closeDrawer"
      class="has-background-white">
      <template v-slot:content>
        <keep-alive>
          <component
            :is='componentToDisplayInDrawer'
            :show-modal="showModal"
            :is-affiliate="isAffiliate"
            @close-drawer="closeDrawer"
            @show-save-modal="showSaveModal"
            @show-constituent-info-form="showConstituentInfoForm"
            @show-message-preview="showMessagePreview">
          </component>
        </keep-alive>
      </template>
    </FullScreenModal>
  </transition>
</template>

<script>
import { markRaw } from "vue";
import FullScreenModal from './FullScreenModal.vue'
import ConstituentInfoForm from './ConstituentInfoForm.vue'
import ReviewMessage from './ReviewMessage.vue'
import { messageActionBus } from '../message-action'

export default {
  name: 'ReviewAndSubmitDrawer',
  components: {
    FullScreenModal,
    ConstituentInfoForm,
    ReviewMessage,
  },
  props: {
    isAffiliate: false
  },
  data() {
    return {
      isDrawerOpen: false,
      componentToDisplayInDrawer: markRaw(ConstituentInfoForm),
      useTransition: true,
      showModal: false,
      firstName: '',
      lastName: '',
    }
  },
  methods: {
    openDrawer: function () {
      this.isDrawerOpen = true
    },
    closeDrawer: function () {
      this.isDrawerOpen = false
    },
    showMessagePreview: function () {
      this.componentToDisplayInDrawer = markRaw(ReviewMessage)
    },
    showConstituentInfoForm: function () {
      this.componentToDisplayInDrawer = markRaw(ConstituentInfoForm)
    },
    showSaveModal: function () {
      this.showModal = true
      this.showConstituentInfoForm()
      setTimeout(() => {
        this.showModal = false
      }, 3000)
    },
  },
  created() {
    // we want the drawer to open automatically
    // when there are error messages,
    // since the errors are only located in the drawer
    // for mobile view
    const serverErrorMessages = document.querySelector('#springboard-server-messages .error')
    if (serverErrorMessages) {
      this.useTransition = true
      this.openDrawer()
    }

    messageActionBus.on('ctaClicked', () => {
      this.openDrawer()
    })
  },
}
</script>

<style lang="scss" scoped>
/* CSS transition classes automatically applied by vue
* for the transition component
* Pre-pended with the transition name.
*/

// specifify a transition animation when the
// drawer slides open and close
// Take  30 seconds to transform from the tranlsateY start
// position to the translateY end position
.slide-leave-active,
.slide-enter-active {
  transition: transform 0.5s;
}

// close drawer - slide to bottom from top of viewport
// or visible->hidden
.slide-leave-to {
  transform: translateY(100vh);
}

// open drawer - slide from bottom of viewport to top
// or hidden->visible
.slide-enter {
  transform: translateY(100vh, 0);
}

@media (prefers-reduced-motion: reduce) {

  .slide-leave-active,
  .slide-enter-active {
    transition: none;
  }
}
</style>