<template>
  <div>
    <section class="mb-sm">
     <div class="content">
      <div class="msg-subject block mt-md">
        <SpringboardText v-if="hasEditableSubject" ref="editSubjectElementWrapper" :sourceElement="editSubjectSourceElement" :labelInline="false" label="Subject:" />
        <div  v-else>
          <p class="has-text-weight-bold mb-xxs">Subject:</p>
          <div class="mb-xxs readonly-subject" v-html="readOnlySubject"  aria-readonly="true"></div>
        </div>
      </div>
      <div class="msg-body block">
        <p class="has-text-weight-bold mb-xxs">Body:</p>
        <div class="mb-sm readonly-header" v-html="readOnlyMsgHeader" aria-readonly="true"></div>
        <div class="msg-body">
          <SpringboardTextArea v-if="hasEditableBody" ref="editMsgElementWrapper" :sourceElement="editBodySourceElement" :showLabel="false" />
        </div>
        <div class="mt-sm" v-if="hasEditableBody" v-html="readOnlyMsgFooter" aria-readonly="true"></div>
      </div>
      <div>
        <p class="mb-md" v-html="signature"></p>
        <p>{{ firstNameSignature }} {{ lastNameSignature }}</p>
      </div>
     </div>
    </section>
  </div>
</template>

<script>
import SpringboardText from "./form-fields/SpringboardText.vue"
import SpringboardTextArea from "./form-fields/SpringboardTextArea.vue"
import { messageActionBus } from '../message-action'

export default {
  name: "MessagePreview",
  components: {
    SpringboardText,
    SpringboardTextArea,
  },
  data() {
    const signatureElement = document.querySelector('.sba-message[id^="edit-sba-messages-message-"] > p')
    return {
      messageActionMsgId: '',
      defaultEmailMsg: '',
      defaultSubjectMsg: '',
      signature: signatureElement ? signatureElement.innerText : '',
      firstName: document.getElementById('edit-submitted-sbp-first-name').value,
      lastName: document.getElementById('edit-submitted-sbp-last-name').value,
    }
  },
  created() {
    // The message action message fields are a separate form from the message action node
    // and have all of its form and applicable html div elements pre-pended with the
    // same message action message id
    if (document.querySelector('div.sba-message.form-wrapper') ) {
      this.messageActionMsgId = document.querySelector('div.sba-message.form-wrapper').id
    }
    // Let components that are not the parent of this component initiate a reset or update of the
    // editable message and subject fields content
    messageActionBus.on('reset-edit-preview', () => {
      this.resetEditableFields()
      this.$emit('cancel-edits')
    })
    messageActionBus.on('update-edit-preview', () => {
      this.backupEditableFields()
      this.$emit('updated-message-text')
    })
    messageActionBus.on('update-first-name', (newValue) => {
      this.firstName = newValue
    })
    messageActionBus.on('update-last-name', (newValue) => {
      this.lastName = newValue
    })
  },
  mounted() {
    // save the default text for both fields
    this.backupEditableFields()
  },
  methods: {
    getSourceElementById(id) {
      return document.getElementById(id)
    },
    backupEditableFields() {
      // Supports the save button in the mobile view
      // where we only save user edits if the explicitely hit the save button
      // otherwise any updates are automatically reset when they close the drawer.
      if (this.hasEditableBody) {
        this.defaultEmailMsg = this.editBodySourceElement.value.trim()
      }
      if (this.hasEditableSubject) {
        this.defaultEmailSubject = this.editSubjectSourceElement.value.trim()
      }
    },
    resetEditableFields() {
      if (this.hasEditableBody) {
        this.editBodySourceElement.value = this.defaultEmailMsg
      }
      if (this.hasEditableSubject) {
        this.editSubjectSourceElement.value = this.defaultEmailSubject
      }
    },
  },
  computed: {
    readOnlyMsgHeader() {
      let hasMsgHeader = this.getSourceElementById(this.messageActionMsgId + '-body-show')
      return (hasMsgHeader == null) ? '' : hasMsgHeader.innerHTML.trim()
    },
    readOnlyMsgFooter() {
      let hasMsgFooter = this.getSourceElementById(this.messageActionMsgId + '-body-bottom')
      return (hasMsgFooter == null) ? '' : hasMsgFooter.innerHTML.trim()
    },
    readOnlySubject() {
      let hasSubject = this.getSourceElementById(this.messageActionMsgId + '-subject-show')
      return (hasSubject == null) ? '' : hasSubject.innerHTML.trim()
    },
    editBodySourceElement() {
      return this.getSourceElementById(this.messageActionMsgId + '-edited-body')
    },
    editSubjectSourceElement() {
      return this.getSourceElementById(this.messageActionMsgId + '-subject')
    },
    hasEditableBody() {
      return (this.editBodySourceElement == null) ? false : true
    },
    hasEditableSubject() {
      return (this.editSubjectSourceElement == null) ? false : true
    },
    firstNameSignature() {
      return (this.firstName.trim() !== '') ? this.firstName : '[First Name]'
    },
    lastNameSignature() {
      return (this.lastName.trim() !== '') ? this.lastName : '[Last Name]'
    }
  }
}
</script>
<style lang="scss" scoped>
.readonly-header {
  > :deep(label) {
    display: none;
  }
}
.readonly-subject {
  > :deep(label) {
    display: none;
  }
}
</style>