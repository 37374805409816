<template>
  <div class="input-wrapper is-flex-wrap-wrap">
    <label :for="vueElementId" :style="labelCssVars" class="has-text-weight-bold mb-xxs">
      {{ elementLabel }}
      <span v-if="required" title="This field is required.">*</span>
    </label>
    <input
      :type="sourceElement.type"
      :name="sourceElement.name"
      :required="required"
      :class="sourceElement.className"
      :id="vueElementId"
      :maxlength="sourceElement.maxLength"
      :style="inputCssVars"
      ref="inputElement"
      v-model="inputText" />
    <div v-if="hasError" class="error-icon" />
  </div>
</template>

<script>
import { observeElement } from '../../index'

export default {
  name: "SpringboardText",
  props: {
    sourceElement: {
      type: HTMLInputElement,
      required: true,
    },
    labelInline: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      required: this.sourceElement.classList.contains('required'),
      defaultLabel: this.sourceElement.parentElement.querySelector('label').textContent.replace(" *", "").trim(),
      hasError: this.sourceElement.classList.contains('error'),
      inputText: this.sourceElement.value
    }
  },
  watch: {
    inputText: function (val) {
      this.$emit('fieldValueChange', val)
    }
  },
  computed: {
    labelCssVars() {
      return {
        '--label-position': this.labelInline ? 'absolute' : 'relative',
        '--label-top': this.labelInline ? '7px' : '0',
        '--label-left': this.labelInline ? '10px' : '0',
        '--label-font-size': this.labelInline ? '13px' : '1em'
      }
    },
    inputCssVars() {
      return {
        '--input-padding': this.labelInline ? '18px' : '5px',
      }
    },
    elementLabel() {
      if (this.label === '')
        return this.defaultLabel
      else
        return this.label
    },
    vueElementId() {
      return this.sourceElement.id + '-vue'
    }
  },
  created() {
    // watches the SB element's value for changes
    // and applies them to the vue component's value
    observeElement(this.sourceElement, 'value', this, function (oldValue, newValue) {
      if (newValue != this.inputText) {
        this.inputText = newValue
      }
    })
  },
  mounted() {
    this.$refs.inputElement.addEventListener("input", (event) => {
      if (this.sourceElement.value != this.inputText) {
        this.sourceElement.value = this.inputText
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";
@import "../../styles/mixins";
@import '../../styles/error';

.input-wrapper {
  position: relative;
  z-index: 1000;
  display: flex;
  width: 100%;

  label {
    position: var(--label-position);
    top: var(--label-top);
    left: var(--label-left);
    z-index: 2;
    font-size: var(--label-font-size);
    font-family: $aclu-sans-standard;
    line-height: 1.4;
    letter-spacing: 0rem;
    word-spacing: 0.0625rem;
  }

  input {
    @include text-input;
    width: 100%;
    height: $inputHeightLarge;
    padding: var(--input-padding) 9px 0;
  }
}
</style>
