<template>
  <a
    v-if="url"
    :href="url"
    :class="socialIcon"
    class="round-icon mr-xs is-inline-block"
    :alt="message"
    rel="noopener"
    target="_blank"
    :title="message"
  >
    <span class="screenreader-only">{{ message }}</span>
  </a>
</template>

<script>
export default {
  name: "FooterSocialLink",
  props: {
    affiliateName: {
      type: String,
      default: "",
    },
    socialName: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ['Facebook', 'X', 'Instagram', 'YouTube', 'TikTok', 'Threads'].includes(value)
      },
    },
    url: {
      type: String,
      default: ""
    },
  },
  computed: {
    message() {
      return `Follow the ${this.affiliateName} on ${this.socialName}`;
    },
    socialIcon() {
      switch (this.socialName) {
        case 'Facebook':
          return 'is-facebook'
        case 'X':
          return 'is-twitter'
        case 'YouTube':
          return 'is-youtube'
        case 'Instagram':
          return 'is-instagram'
        case 'TikTok':
          return 'is-tiktok'
        case 'Threads':
          return 'is-threads'
      }
    }
  }
};
</script>
