<template>
  <div>
    <button ref="watchedFixedCta" v-setup-cta-button="ctaText" class="button has-background-orange is-fullwidth mb-md wrap-button-text hp__messageaction_fixed_cta" />
    <div v-show="showSticky" :style="stickyCssVars" class="sticky has-background-orange">
      <button v-setup-cta-button class="button container is-flex-wrap-nowrap has-background-orange is-fullwidth has-text-left wrap-button-text hp__messageaction_sticky_cta">
        <div class="is-flex-grow-2">{{ ctaText }}</div>
        <div class="pr-sm"><Caret :width="10" :rotate='caretRotation' /></div>
      </button>
    </div>
  </div>
</template>

<script>
import Caret from '@aclu-national/aclu-vue-library/src/components/common/Caret.vue'
import { configuredSubmitButtonText } from '../message-action'

export default {
  name: "StickyButton",
  props: {
    caretRotation: {
      type: Number,
      default: 0
    },
  },
  components: {
    Caret
  },
  data() {
    return {
      ctaObserver: null,
      footerObserver: null,
      showSticky: false,
      isAbsolute: false,
      ctaHeight: "45",
      ctaText: configuredSubmitButtonText,
    }
  },
  mounted() {
    // Create an observer to track when the fixed cta button scrolls into and out of view
    // When the fixed cta is out of view the sticky cta will appear at the bottom of the
    // viewport and move as the windows scrolls until it reaches the bottom of the scrollarea
    //  at the bottom it will anchor to the page above the footer and become fixed in place.
    // Scrollarea = the viewport above the footer and below the fixed cta
    this.ctaObserver = new IntersectionObserver(this.checkCtaVisibility)
    this.ctaObserver.observe(this.$refs.watchedFixedCta)

    // get fixed cta height to style the sticky cta height
    this.ctaHeight = this.$refs.watchedFixedCta.clientHeight

    // Create an observer to just toggle the sticky from floating to fixed above the footer
    // leave the visibility to the cta observer
    const footer = document.getElementById('footer')
    if (footer) {
      this.footerObserver = new IntersectionObserver(this.checkFooterVisibility)
      this.footerObserver.observe(footer)
    }
  },
  beforeUnmount() {
    this.ctaObserver.disconnect()
    if (this.footerObserver)
      this.footerObserver.disconnect()
  },
  computed: {
    stickyCssVars() {
      return {
        '--sticky-position': this.isAbsolute ? 'absolute' : 'fixed',
        '--sticky-height': (this.ctaHeight-10) * 2 + "px",
      }
    }
  },
  methods: {
    checkCtaVisibility(targetElement) {
        // the targetElement is an IntersectionObserverEntry object
        // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
        // isIntersecting: use this property to determine whether or not the targetElement is in view
        // boundingClientRect: providing information about the size of an element and its
        // position relative to the browser viewport
        targetElement.forEach(({ isIntersecting, boundingClientRect }) => {
          // when cta is visible and when viewing content below the cta
          if (isIntersecting) {
            // when cta is visble
            this.showSticky = false
          } else if (boundingClientRect.y < 0) {
            // when viewing content below the cta
            this.showSticky = true
          }
        })
      },
      checkFooterVisibility(targetElement) {
        // the targetElement is an IntersectionObserverEntry object
        // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
        // isIntersecting: use this property to determine whether the targetElement is in viewport
        targetElement.forEach(({ isIntersecting }) => {
          // when footer is visible and when viewing content below the footer
          if (isIntersecting) {
            // when footer is visble
            // pin the sticky cta above the footer
            this.isAbsolute = true
          } else {
            // float the sticky cta when footer is not visible
             this.isAbsolute = false
          }
        })
      },
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.button:focus, .button.is-focused {
  border-color: inherit;
  color: inherit;
}

button.wrap-button-text {
  min-height: $inputHeight;
  height: auto;
  white-space: inherit;
}

.sticky {
  position: var(--sticky-position);
  bottom: 0;
  left: 0;
  z-index: 3;
  height: var(--sticky-height);
  width: 100%;
  .button {
    height: var(--sticky-height);
  }
}
</style>
