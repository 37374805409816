// create the Vue app that will be used to register vue components
// loaded into the pagewrapper
import { createApp } from 'vue'

// Import vue library components
import {
  BrandedFooter,
  BrandedNav,
  ResponsivePageWrapper,
} from '@aclu-national/aclu-vue-library'

import AffiliateFooter  from "./components/AffiliateFooter.vue"

import '@aclu-national/aclu-vue-library/dist/aclu-vue-library.css'
import './styles/global-vue-styles.scss'

// global util apps
const acluNavApp = createApp({})
const acluFooterApp = createApp({})
const acluServerMessagesApp = createApp({})
const affiliateFooterApp = createApp({})

// Import springboard repo components
import ServerMessages from './components/ServerMessages.vue'

// Register components globally
// so they can be compiled and mounted
// in our pagewrappers/in-DOM template vue application)

// When you’re using in-DOM template apps (our pagewrappers), components and prop names
// all need to use their kebab-cased (hyphen-delimited) equivalents.
acluNavApp.component('branded-nav', BrandedNav)
acluFooterApp.component('branded-footer', BrandedFooter)
acluServerMessagesApp.component('server-messages', ServerMessages)
affiliateFooterApp.component('affiliate-footer', AffiliateFooter)

// move admin info [e.g. tabs (e.g. view, edit, clone)]
// which is part of the hidden '[content]' tag to where we can see them
document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.tabs-container') && document.querySelector('.tabs')) {
    document.querySelector('.tabs-container').innerHTML = document.querySelector('.tabs').innerHTML
  }
})

const isInViewport = function (element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const allFieldsValid = function (formFields) {
  return Array.from(formFields).every((field) => {
    const isCurrentFieldValid = field.reportValidity()
    if (!isCurrentFieldValid && !isInViewport(field)) {
      // safari on iOS wasn't always showing the field that had
      // an error, so we check if the field is visible and if not
      // we scroll to it.
      field.scrollIntoView()
    }
    if (!isCurrentFieldValid && (field !== document.activeElement)) {
      // firefox on android doesn't play well with native browser validation
      // (see: https://bugzilla.mozilla.org/show_bug.cgi?id=1510450), so we
      // do some of the work for them here.
      field.focus()
    }
    return isCurrentFieldValid
  })
}

export const prepopulateCityState = function (zipCode) {
  const cityField = document.getElementById('edit-submitted-sbp-city')
  const stateField = document.getElementById('edit-submitted-sbp-state')
  const countryField = document.getElementById('edit-submitted-sbp-country')
  if (!cityField || !stateField) {
    return
  }
  const cityFieldIsEmpty = !cityField.value.trim()
  const stateFieldIsEmpty = !stateField.value.trim()

  // We check if the city or state fields are already populated so that we don't
  // overwrite anything the user manually input.
  if ((/^\d{5}$/.test(zipCode)) && (cityFieldIsEmpty || stateFieldIsEmpty)) {
    fetch("/zip_lookup/autopop-city-state-by-zip", {
      "headers": {
        "content-type": "application/x-www-form-urlencoded",
      },
      "body": `zip=${zipCode}`,
      "method": "POST",
    })
    .then(response => response.json())
    .then((responseJSON) => {
      if (responseJSON.city && cityFieldIsEmpty) {
        cityField.value = responseJSON.city
      }
      if (responseJSON.state_id && stateFieldIsEmpty) {
        stateField.value = responseJSON.state_id
      }
      if (countryField) {
        if (responseJSON.state_id || responseJSON.state_id) {
          document.getElementById('edit-submitted-sbp-country').value = 'US'
        } else {
          document.getElementById('edit-submitted-sbp-country').value = ''
        }
      }
    })
  }
}

// used to run some code (the callback function that's passed in) when a form element property
// (usually its value) is changed via JS only (e.g. document.getElementById('example').value = 1).
// this is useful in cases where Springboard JS code (e.g. autofill or autopopulation of state when
// zip is filled in) updates a field and you want that update reflected in a related vue component
// field.
// inspired by https://stackoverflow.com/a/61975440
export const observeElement = function (element, property, vueComponent, callback) {
  let elementPrototype = Object.getPrototypeOf(element);
  if (elementPrototype.hasOwnProperty(property)) {
    let descriptor = Object.getOwnPropertyDescriptor(elementPrototype, property);
    Object.defineProperty(element, property, {
      configurable: descriptor.configurable,
      enumerable: descriptor.enumerable,
      get: function() {
        return descriptor.get.apply(this, arguments);
      },
      set: function () {
        let oldValue = this[property];
        descriptor.set.apply(this, arguments);
        let newValue = this[property];
        if (typeof callback == "function") {
          setTimeout(callback.bind(vueComponent, oldValue, newValue));
        }
      }
    });
  }
}

// When we use the approach of hiding the underlying Springboard form,
// heap fails to trigger a 'Submit on' event like it does for regular
// forms. So, we use this function to manually fire a submission event
// so we can track the submission in heap.
export const trackHiddenFormSubmitInHeap = function () {
  if (typeof heap !== 'undefined') {
    heap.track('Submit - hidden Springboard form')
  }
}

acluNavApp.config.compilerOptions.whitespace = 'preserve'
acluFooterApp.config.compilerOptions.whitespace = 'preserve'
acluServerMessagesApp.config.compilerOptions.whitespace = 'preserve'
affiliateFooterApp.config.compilerOptions.whitespace = 'preserve'

if (document.getElementById('aclu-nav-app'))
  acluNavApp.mount('#aclu-nav-app')

if (document.getElementById('aclu-footer-app'))
  acluFooterApp.mount('#aclu-footer-app')

if (document.getElementById('aclu-server-messages-app'))
  acluServerMessagesApp.mount('#aclu-server-messages-app')

if (document.getElementById('affiliate-footer-app'))
  affiliateFooterApp.mount('#affiliate-footer-app')
