<template>
  <ResponsivePageWrapper v-if="springboardWebform">
    <template v-slot:default="{ isTouch }">
      <section
        class="body-section review-section"
        :class="{ container: isTouch }"
      >
        <section class="mb-sm mt-sm" v-if="isTouch">
          <button  @click="cancelEdit" class="button is-flex is-fullwidth is-flex-wrap-nowrap has-text-centered hp__messageaction_preview_cancel" aria-label="close preview">
              <div><Caret :width="10" :rotate="180" /></div>
              <div class="is-flex-grow-2"><h3>Preview message</h3></div>
          </button>
        </section>
        <section class="mb-sm mt-sm" v-if="!isTouch">
          <hr class="review-msg-section">
          <h2>Review Message</h2>
        </section>
        <section>
          <div :class="{ container: isTouch }">
            <p>We put in some suggestions, but make the message your own where possible!</p>
          </div>
        </section>
        <section class="mb-lg">
          <p v-if="addressee" class="has-text-weight-bold mb-sm mt-md">
            To: <span class="has-text-weight-normal">{{ addressee }}</span>
          </p>
          <MessagePreview
            @cancel-edits="$emit('show-constituent-info-form')"
            @updated-message-text="$emit('show-save-modal')"
          />
        </section>
        <button
          class="button mb-md is-fullwidth is-hidden-desktop is-black hp__messageaction_preview_save"
          @click="saveEdit"
        >
          Save
        </button>
      </section>
    </template>
  </ResponsivePageWrapper>
</template>

<script>
import MessagePreview from "./MessagePreview.vue";
import { ResponsivePageWrapper } from "@aclu-national/aclu-vue-library";
import Caret from '@aclu-national/aclu-vue-library/src/components/common/Caret.vue'
import { messageActionBus } from "../message-action";

export default {
  name: "ReviewMessage",
  components: {
    MessagePreview,
    Caret,
    ResponsivePageWrapper,
  },
  data() {
    const sourceAddressee = document.querySelector('[id^="edit-sba-messages-message-"][id$="-greeting-show"]')
    return {
      addressee: sourceAddressee ? sourceAddressee.innerText.trim() : '',
      springboardWebform: document.querySelector('form.webform-client-form')
    }
  },
  methods: {
    cancelEdit() {
      messageActionBus.emit("reset-edit-preview");
    },
    saveEdit() {
      messageActionBus.emit("update-edit-preview");
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.scss';
.caret {
  padding-left: 0;
  margin-left: -10px;
  position: absolute;
}

.review-msg-section {
  background-color: $grey-20;
  height: 0.5px;
}
</style>
