<template>
  <div class="select">
    <label :for="vueElementId" class="is-size-7">
      {{ label }}
      <span v-if="required" title="This field is required.">*</span>
    </label>
    <select
      :multiple="this.sourceElement.type === 'select-multiple'"
      ref="select"
      :name="sourceElement.name"
      :required="required"
      :class="sourceElement.className"
      :id="vueElementId"
      v-model="selectDropdown"
      @change="updateValue">
      <option v-for="option in sourceElement.options" :key="option.index" :value="option.value">{{ option.text }}</option>
    </select>
    <div v-if="hasError" class="error-icon" />
  </div>
</template>

<script>
import { observeElement } from '../../index'

export default {
  name: "SpringboardDropdown",
  props: {
    /**
     * Expects a select HTML Element that is in the DOM which this component
     *  will recreate in VUE and remove from the DOM
     *  https://developer.mozilla.org/en-US/docs/Web/API/HTMLSelectElement
     */
    sourceElement: {
      type: HTMLSelectElement,
    },
    /**
     *  Allow the parent component to override the sourceElement's `required` attribute
     *  Useful if a field is only required under certain circumstances.
     */
    requiredOverride: {
      type: Boolean,
      default: null,
    }
  },
  data() {
    return {
      label: this.sourceElement.parentElement.querySelector('label').textContent.replace(" *", "").trim(),
      sourceRequired: this.sourceElement.classList.contains('required'),
      hasError: this.sourceElement.classList.contains('error'),
      selectDropdown: this.sourceElement.value
    }
  },
  created() {
    // watches the SB element's value for changes
    // and applies them to the vue component's value
    observeElement(this.sourceElement, 'value', this, function (oldValue, newValue) {
      if (newValue != this.selectDropdown) {
        this.selectDropdown = newValue
      }
    })
  },
  mounted() {
    this.$refs.select.addEventListener("change", (event) => {
      if (this.sourceElement.value != this.selectDropdown) {
        this.sourceElement.value = this.selectDropdown
      }
    })
  },
  computed: {
    required: function () {
      return this.requiredOverride !== null ? this.requiredOverride : this.sourceRequired
    },
    vueElementId() {
      return this.sourceElement.id + '-vue'
    }
  },
  methods: {
    updateValue() {
      this.$emit('valueChanged', this.$refs.select.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";
@import '../../styles/error';

.select {
  width: 100%;

  &:not(.is-multiple) {
    height: 60px;
  }

  select {
    width: 100%;
    border: 2px solid;
  }

  //bulma select style override
  select:not([multiple]) {
    padding-top: 24px;
    padding-left: 8px;
    height: 60px;
    -moz-padding-start: 0.3rem;
  }

  &:not(.is-multiple):not(.is-loading)::after,
  select:hover {
    border-color: $offblack;
  }

  label {
    position: absolute;
    top: 7px;
    left: 10px;
    z-index: 1;
  }
}
</style>
