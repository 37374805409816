// create the Vue app that will be used to register
// custom vue components for this form type loaded into the pagewrapper
import { createApp } from 'vue'

const ReviewAndSubmitApp = createApp({})
const StickyButtonApp = createApp({})
const ConstituentInfoFormApp = createApp({})
const ReviewMessageApp = createApp({})

/**
 * IN-DOM PRE mount config
 */

// implements eventBus
import mitt from 'mitt'

// Import styles
import './styles/message-action.scss'

// Import springboard repo components
import ReviewMessage from './components/ReviewMessage.vue'
import ConstituentInfoForm from './components/ConstituentInfoForm.vue'
import ReviewAndSubmitDrawer from './components/ReviewAndSubmitDrawer.vue'
import StickyButton from './components/StickyButton.vue'
import { ResponsivePageWrapper } from '@aclu-national/aclu-vue-library'

// Register vue components globally so can be mounted in pagewrapper
ReviewAndSubmitApp.component('review-and-submit-drawer', ReviewAndSubmitDrawer)
ReviewAndSubmitApp.component('responsive-page-wrapper', ResponsivePageWrapper)
StickyButtonApp.component('sticky-button', StickyButton)
StickyButtonApp.component('responsive-page-wrapper', ResponsivePageWrapper)
ConstituentInfoFormApp.component('constituent-info-form', ConstituentInfoForm)
ConstituentInfoFormApp.component('responsive-page-wrapper', ResponsivePageWrapper)
ReviewMessageApp.component('review-message', ReviewMessage)
ReviewMessageApp.component('responsive-page-wrapper', ResponsivePageWrapper)


export const messageActionBus = mitt()
export const configuredSubmitButtonText = document.getElementById('edit-submit') ? document.getElementById('edit-submit').value : 'Send my message'

// for the sticky form: make the form static if the browser height is shorter
// than the form, to prevent a portion of the form being cut off while scrolling
window.addEventListener('DOMContentLoaded', () => {
    let timeout = false
    const delay = 500

    // we want to keep the goal element in the body of the message action node
    // and the social proof element in the pagewrapper. but we want the goal element to
    // appear above the social proof element. thus, we need to swap them here.
    const socialProofElement = document.getElementById('participants-container')
    const goalElement = document.querySelector('.wg-goal')
    if (socialProofElement && goalElement) {
        goalElement.after(socialProofElement)
    }

    function toggleStickyForm() {
        const stickyForm = document.querySelector('.message-action-form-desktop-container')
        if (stickyForm.offsetHeight && stickyForm.offsetHeight > window.innerHeight) {
            stickyForm.style.position = 'static'
        } else {
            stickyForm.style.position = 'sticky'
        }
    }

    window.addEventListener('resize', () => {
        clearTimeout(timeout)
        timeout = setTimeout(toggleStickyForm, delay)
    })

    toggleStickyForm()
})

StickyButtonApp.directive('setup-cta-button', {
    mounted: function (el, binding) {
        if (binding.value) {
            el.textContent = binding.value
        }
        el.addEventListener("click", function() {
            messageActionBus.emit('ctaClicked')
        });
    }
})

// APP CONFIG OPTIONS
StickyButtonApp.config.compilerOptions.whitespace = 'preserve'
ConstituentInfoFormApp.config.compilerOptions.whitespace = 'preserve'
ReviewMessageApp.config.compilerOptions.whitespace = 'preserve'
ReviewAndSubmitApp.config.compilerOptions.whitespace = 'preserve'

// MOUNT LAST
StickyButtonApp.mount('#sticky-button-app')
ConstituentInfoFormApp.mount('#constituent-info-form-app')
ReviewMessageApp.mount('#review-message-app')

if (document.getElementById('review-and-submit-drawer-app'))
    ReviewAndSubmitApp.mount('#review-and-submit-drawer-app')

// the hidden Springboard HTML contains an empty header tag,
// let's remove it to improve accessibility
document.addEventListener('DOMContentLoaded', (event) => {
  const ctaElement = document.getElementById('call-to-action')
  if (ctaElement) {
      ctaElement.remove()
  }
})
